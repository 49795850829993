/* @import url('style.css'); */
html {
  height: 100%;
}
body {
  line-height: 120%;
  min-height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}
#body_wrapper {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
}

.whatsappbtn {
  position: fixed;
  width: 66px;
  height: 66px;
  right: 24px;
  bottom: 100px;
  z-index: 999;
}
.whatsappbtn img {
  display: block;
  width: 100%;
  height: 100%;
}
.phonebtn {
  position: fixed;
  width: 50px;
  height: 50px;
  right: 30px;
  bottom: 40px;
  z-index: 999;
}
.phonebtn img {
  display: block;
  width: 100%;
  height: 100%;
}

/* Header and Footer S */

.header_container {
  padding: 15px 0px;
  position: relative;
  z-index: 9;
  background-color: #fdfdfd;
  border-bottom: 1px solid #f6f6f6;
}
.header_container .navbar {
  padding: 0;
  position: relative;
}
.header_container.headerfixed {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  transition: all 0.3s ease-in-out;
  box-shadow: 1px 1px 30px 5px #ffe7e4;
}
.header_container .inner {
  width: 100%;
  position: relative;
}
.header_container .inner .nav-right {
  display: flex;
  align-items: center;
}
.header_container .inner .logo a img {
  height: 95px;
  display: block;
}
.header_container .inner .logo img {
  display: block;
  width: 100%;
}
.header_container .inner .main_nav {
  position: relative;
  justify-content: flex-end;
}
.header_container .inner .main_nav .menu_wrap {
  margin-right: 15px;
}
.header_container .inner .main_nav .menu_wrap ul {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}
.header_container .inner .main_nav .menu_wrap ul li {
  margin: 0 15px;
}
.header_container .inner .main_nav .menu_wrap ul li a {
  font-size: 18px;
  line-height: 26px;
  letter-spacing: 0.75px;
  text-transform: uppercase;
}
.header_container .inner .main_nav .menu_wrap ul li a:hover,
.header_container .inner .main_nav .menu_wrap ul li.active a {
  color: var(--anchorhover);
}
.header_container .inner .header-search {
  margin-left: 15px;
}
.header_container .inner .header-search.mb_search,
.header_container .inner .header-search img.close-icon {
  display: none;
}
.header_container .inner .header-search .search-icon {
  display: block;
}
.header_container .inner .header-search.active .close-icon {
  display: block;
}
.header_container .inner .header-search.active .search-icon {
  display: none;
}
.header_container .inner .header-search .form-dropdown {
  padding: 15px;
  position: absolute;
  right: 0;
  z-index: 1;
  background-color: #fdfdfd;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.5s ease-in-out;
  -khtml-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}
.header_container .inner .header-search .form-dropdown .form-control {
  height: 82%;
  border: 0;
  border-bottom: 2px solid #666;
  border-radius: 0;
  z-index: -9;
}
.header_container .inner .header-search .form-dropdown .form-control:focus {
  outline: none;
  border-color: unset;
  outline: unset;
  box-shadow: unset;
}
.header_container .inner .header-search .search_list {
  position: absolute;
  top: 50px;
  right: 40px;
  max-width: 500px;
  width: 500px;
  z-index: 999;
  background-color: white;
  display: flex;
  align-items: center;
  opacity: 0;
  box-shadow: 1px 1px 15px #ddd;
  border-radius: 10px;
}
.header_container .inner .header-search.active .form-dropdown {
  opacity: 1;
  visibility: visible;
}
.header_container
  .inner
  .header-search
  .form-dropdown
  input:not([type="radio"]):not([type="checkbox"]):not([type="submit"]):not(
    [type="button"]
  ):not([type="image"]):not([type="file"]) {
  padding: 12px 15px;
  z-index: 999;
  height: 52px;
  width: 100%;
  background-color: #fdfdfd;
  border-bottom: 1px solid #666;
  margin: 0;
  border-radius: 0;
}
.header_container .inner .header-search .search_list .navi {
  max-height: 100% !important;
  width: 100% !important;
  overflow: auto !important;
  padding: 0;
  height: auto !important;
  padding: 5px;
}
.header_container
  .inner
  .header-search
  .search_list
  .navi
  .navi-item:last-child
  .navi-link {
  padding: 15px;
  display: block;
  border-bottom: unset;
}
.header_container .inner .header-search .search_list .navi .navi-link {
  padding: 15px;
  display: block;
  border-bottom: 1px solid #8e9060;
  letter-spacing: 1.2px;
}
.header_container .inner .header-search .search_list .navi .navi-link:hover,
.header_container .inner .header-search .search_list .navi .navi-link:focus {
  background-color: #8e9060;
  color: #fff;
}
.header-search .search-link {
  z-index: 9;
  display: block;
  padding: 0;
  margin: 0;
  width: 20px;
  height: 20px;
  position: relative;
}
.header-search .search-link img {
  display: block;
  width: 100%;
  height: 100%;
}

.footer_container {
  background: var(--footer_color);
}
.footer_container .inner {
  padding: 60px 0 40px;
  width: 100%;
}
.footer_container .inner .logo {
  margin-bottom: 35px;
}
.footer_container .inner .logo img {
  filter: brightness(0) invert(1);
}
.footer_container .inner p,
.footer_container .inner a {
  font-size: 14px;
  line-height: 26px;
}
.footer_container .inner a {
  display: block;
}
.footer_container .inner p {
  margin: 0;
}
.footer_container .inner .menu_wrapper {
  margin-bottom: 20px;
}
.footer_container .inner .menu_wrapper ul li {
  padding: 5px 10px;
}
.footer_container .inner .menu_wrapper ul li a {
  display: block;
  font-size: 18px;
  line-height: 26px;
  font-weight: 400;
  color: #999;
  letter-spacing: 0.75px;
}
.footer_container .inner .menu_wrapper ul li a:hover {
  color: var(--white);
}
.footer_container .inner .col {
  padding: 15px;
}
.footer_container .copyright_txt {
  padding: 0;
  font-size: 16px;
  line-height: 24px;
  color: #999;
}

@media (min-width: 768px) {
  .header_container .inner .header-search .form-dropdown {
    top: -7px;
    width: 720px;
  }
}
@media (min-width: 992px) {
  .header_container .inner .header-search .form-dropdown {
    top: -30px;
    width: 960px;
  }
}
@media (min-width: 1200px) {
  .header_container .inner .header-search .form-dropdown {
    width: 1140px;
    padding: 20px;
  }
}
@media (min-width: 1400px) {
  .header_container .inner .header-search .form-dropdown {
    width: 1320px;
    padding: 25px;
  }
}
@media (min-width: 1600px) {
  .container {
    max-width: 1530px;
  }
  .header_container .inner .header-search .form-dropdown {
    width: 1530px;
  }
}

/* Header and Footer E */

/*===========================================*/
/***************** Home Page *****************/
/*===========================================*/

.bannerSec .banner_sl .slider .slider_wrap .inner {
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  padding: 50px 0;
}
.bannerSec .banner_sl .slider .slider_wrap .inner .left {
  width: 50%;
}
.bannerSec .banner_sl .slider .slider_wrap .inner .left .thumbnail_wrap {
  height: 650px;
  padding-right: 100px;
}
.bannerSec .banner_sl .slider .slider_wrap .inner .left .thumbnail_wrap img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  max-height: 100%;
  max-width: 100%;
}
.bannerSec .banner_sl .slider .slider_wrap .inner .right {
  width: 50%;
}
.bannerSec .banner_sl .slider .slider_wrap .inner .right .detail_wrap {
  height: 100%;
}
.bannerSec
  .banner_sl
  .slider
  .slider_wrap
  .inner
  .right
  .detail_wrap
  .slider-title
  .title {
  margin: 0 0 20px;
  color: #333;
  text-transform: uppercase;
  font-size: 100px;
  line-height: 90px;
  font-size: 100px;
  line-height: 90px;
}
.bannerSec .banner_sl .slider .slider_wrap .inner .right .detail_wrap .content {
  font-size: 22px;
  line-height: 28px;
  color: #1a2027;
}
.bannerSec
  .banner_sl
  .slider
  .slider_wrap
  .inner
  .right
  .detail_wrap
  .slider-button {
  margin-top: 25px;
}
.bannerSec .banner_sl.slick-slider .slick-arrow.slick-next {
  right: 5%;
  left: auto;
}
.bannerSec .banner_sl.slick-slider .slick-arrow.slick-prev {
  left: 5%;
}
.bannerSec .banner_sl.slick-slider .slick-arrow {
  background: transparent;
  border: none;
  z-index: 5;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  width: 50px;
  height: 45px;
  font-size: 0px;
  color: #fff;
  cursor: pointer;
  border-radius: 50rem;
}
.bannerSec .banner_sl.slick-slider .slick-arrow.slick-next:before,
.bannerSec .banner_sl.slick-slider .slick-arrow.slick-prev:before {
  font-family: slick;
  font-size: 43px;
  line-height: 1;
  opacity: 1;
  display: inline-block;
  width: 77%;
  color: #ffffff;
  height: 79%;
  box-shadow: 0 0 18px 4px #ddd;
  border-radius: 50rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.fullImgBanner.bannerSec .banner_sl .slider .slider_wrap .inner {
  padding: 0;
}
.fullImgBanner.bannerSec
  .banner_sl
  .slider
  .slider_wrap
  .inner
  .thumbnail_wrap {
  width: 100%;
  height: 550px;
}
.fullImgBanner.bannerSec
  .banner_sl
  .slider
  .slider_wrap
  .inner
  .thumbnail_wrap
  img {
  width: 100%;
  height: 100%;
  display: block;
}

.shopProd_mn {
  padding: 75px 0 20px;
}
.shopProd_mn .shopProd_title {
  margin-bottom: 30px;
}
.shopAll_mn.shopProd_mn .shopProd_list {
  display: flex;
  flex-flow: row wrap;
}
.shopProd_mn .shopProd_list .shopProd_box {
  display: flex;
  flex-flow: row wrap;
  position: relative;
  margin: 15px;
  border-radius: 0px;
  border: 1px solid #ccc;
  width: 279px;
  /* height: 225px; */
}
.shopProd_mn .shopProd_list .shopProd_box .shopProd_img {
  width: 120px;
}
/*.shopProd_mn .shopProd_list .shopProd_box .shopProd_img { margin: 0 auto; padding-bottom: 100%; border-radius: 0px; overflow: hidden; position: relative; width: 100%; display: block; }*/
.shopProd_mn .shopProd_list .shopProd_box .shopProd_img .shopProd_img_wrapper {
  margin: 0px;
  padding: 0px;
  border: 0 solid rgba(255, 255, 255, 0);
  height: 100%;
  width: 100%;
  background: rgba(255, 255, 255, 1);
}
.shopProd_mn .shopProd_list .shopProd_box .shopProd_img img {
  width: 100%;
  height: 100%;
  object-fit: scale-down;
}
.shopProd_mn .shopProd_list .shopProd_box .shopProd_detail {
  /*width:calc(100% - 120px);*/
  width: 100%;
  /* height: 225px; */
  height: 100%;
  padding: 20px 15px;
  margin: auto;
  font-size: 13px;
  line-height: 20px;
  font-weight: 400;
  color: #333;
  z-index: 9;
  transition: 0.5s;
  background: #fff;
}
.shopProd_mn .shopProd_list .shopProd_box .shopProd_detail .list ul {
  display: flex;
  flex-flow: row wrap;
}
.shopProd_mn .shopProd_list .shopProd_box .shopProd_detail .list ul li {
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  font-size: 14px;
  line-height: 20px;
  width: 33.33%;
  padding: 5px;
  border-right: 1px solid #949494;
}
.shopProd_mn
  .shopProd_list
  .shopProd_box
  .shopProd_detail
  .list
  ul
  li:last-child {
  border-right: 0px solid #e2e2e2;
}
.shopProd_mn .shopProd_list .shopProd_box .shopProd_detail .list ul li span {
  padding: 3px 8px;
  font-weight: 600;
}
.shopProd_mn .shopProd_list .shopProd_box .shopProd_detail .list ul li .winQty {
  font-size: 15px;
  letter-spacing: 0.5px;
  font-weight: 300;
  color: #232323;
  font-family: var(--headingfont);
}
.shopProd_mn .shopProd_list .shopProd_box .shopProd_detail .list ul li .winPrc {
  font-size: 16px;
  font-family: var(--robtslbfont);
  text-align: center;
}
.shopProd_mn
  .shopProd_list
  .shopProd_box
  .shopProd_detail
  .list
  ul
  li
  .winPrc
  small {
  display: block;
  font-size: 12px;
  font-weight: 300;
  text-decoration: line-through;
  text-decoration-color: #ff5b2e;
}
/*.shopProd_mn .shopProd_list .shopProd_box:hover .shopProd_detail { opacity:1; transform:scale(1.0); transition:0.5s; }*/
.shopProd_mn .shopProd_list .shopProd_box .shopProd_detail .title {
  font-size: 18px;
  line-height: 22px;
  font-family: var(--robtslbfont);
  margin: 0 0 5px 0;
  letter-spacing: 0.54px;
  color: black;
}
.shopProd_mn .shopProd_list .shopProd_box .shopProd_detail .content {
  max-width: 350px;
  margin: 0 auto 15px;
  text-align: center;
}
.shopProd_mn .shopProd_list .shopProd_box .shopProd_detail .action .btn {
  padding: 0;
  outline: none;
  display: block;
  margin: 6px;
  width: 26px;
}
.shopProd_mn .shopProd_list .shopProd_box .shopProd_detail .action {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}
.shopProd_mn
  .shopProd_list
  .shopProd_box
  .shopProd_detail
  .action
  .phone-icon.btn
  img {
  filter: brightness(0.5) invert(0.9);
}
.shopProd_mn
  .shopProd_list
  .shopProd_box
  .shopProd_detail
  .action
  .whatsapp-icon.btn
  img {
  filter: contrast(0) sepia(130%) hue-rotate(432deg) brightness(0.61)
    saturate(12.28);
}

.slide_one {
  background: rgb(255, 255, 255);
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.7371323529411764) 21%,
    rgba(240, 240, 240, 1) 49%
  );
}
.slide_two {
  background: rgb(255, 255, 255);
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.7371323529411764) 21%,
    rgba(240, 240, 240, 1) 49%
  );
}
.slide_three {
  background: rgb(255, 255, 255);
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.7371323529411764) 21%,
    rgba(240, 240, 240, 1) 49%
  );
}
.slide_four {
  background: rgb(255, 255, 255);
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.7371323529411764) 21%,
    rgba(240, 240, 240, 1) 49%
  );
}

/* Contact Us Page S */

.main-contact-section {
  padding: 50px 0 75px;
}
.main-contact-section h4.main-title {
  margin-top: 0px;
  margin-bottom: 10px;
  text-align: center;
  font-size: 28px;
}
.main-contact-section .form-wrapper {
  padding: 50px;
}
.main-contact-section .contact-form {
  margin-bottom: 40px;
}
.main-contact-section
  .contact-form
  .form-group
  input:not([type="radio"]):not([type="checkbox"]):not([type="submit"]):not(
    [type="button"]
  ):not([type="image"]):not([type="file"]) {
  font-size: 14px;
  line-height: 26px;
  letter-spacing: 0.5px;
  font-weight: 400;
  border: 1px solid #1a2027;
  color: #1a2027;
  border-radius: 3px;
  display: block;
  width: 100%;
  background-color: #fff;
  box-shadow: none;
  outline: none;
  padding: 12px 15px;
}
.main-contact-section .contact-form .form-group textarea {
  font-size: 14px;
  line-height: 26px;
  border: 1px solid #1a2027;
  border-radius: 3px;
  box-shadow: none;
  outline: none;
  color: #1a2027;
}
.main-contact-section .contact-form .row {
  margin-left: -8px;
  margin-right: -8px;
}
.main-contact-section .contact-form .row .col-sm-12 {
  padding-left: 8px;
  padding-right: 8px;
}
.main-contact-section .main-subtitle {
  margin-bottom: 40px;
  font-size: 15px;
  line-height: 120%;
  color: #000000;
  letter-spacing: 0.5px;
  text-align: center;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}
.main-contact-section .map_wrap .main-map {
  height: 650px;
}
.main-contact-section .map_wrap .main-map iframe {
  width: 100%;
  height: 100%;
}

.shopAll_mn.shopProd_mn .shopProd_list .shopProd_item {
  width: calc(100% / 2 - 4%);
  margin: 0 2%;
  margin-bottom: 4%;
}
.shopAll_mn.shopProd_mn .shopProd_list .box {
  display: flex;
  flex-flow: row wrap;
  background: #fcfcfc;
  box-shadow: 1px 1px 20px 1px #d7d7d7;
  border-radius: 10px;
  overflow: hidden;
  height: 100%;
}
.shopAll_mn.shopProd_mn .shopProd_list .box .top_part {
  display: flex;
  flex-flow: row wrap;
  width: 100%;
}
.shopAll_mn.shopProd_mn .shopProd_list .box .bottom_part {
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  padding: 10px;
  background: rgb(248, 249, 250);
  border-top: 1px solid #ddd;
}
.shopAll_mn.shopProd_mn .shopProd_list .box .img_wrap {
  width: 150px;
  height: auto;
}
.shopAll_mn.shopProd_mn .shopProd_list .box .detail {
  width: calc(100% - 150px);
  display: flex;
  flex-flow: column wrap;
  align-self: center;
  padding: 25px;
}
.shopAll_mn.shopProd_mn .shopProd_list .box .img_wrap img {
  height: 100%;
  width: 100%;
  display: block;
  object-fit: cover;
}
.shopAll_mn.shopProd_mn .shopProd_list .box .detail .category {
  margin: 0 0 5px 0;
}
.shopAll_mn.shopProd_mn .shopProd_list .box .detail .category span {
  color: #000;
  letter-spacing: 0.75px;
  font-size: 15px;
}
.shopAll_mn.shopProd_mn .shopProd_list .box .detail .category .main {
  font-weight: 600;
  font-family: var(--robtslbfont);
}
.shopAll_mn.shopProd_mn .shopProd_list .box .detail .category .sub {
  color: #808080;
  font-weight: 300;
}
.shopAll_mn.shopProd_mn .shopProd_list .box .detail .title h3 {
  font-size: 24px;
  line-height: 34px;
  color: #333;
  margin-bottom: 0;
}
.shopAll_mn.shopProd_mn .shopProd_list .box .detail .content {
  margin: 0 0 10px 0;
}
.shopAll_mn.shopProd_mn .shopProd_list .box .list {
  display: inline-block;
  width: 100%;
}
.shopAll_mn.shopProd_mn .shopProd_list .box .list ul {
  display: flex;
  flex-flow: row wrap;
}
.shopAll_mn.shopProd_mn .shopProd_list .box .list ul li {
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  font-size: 14px;
  line-height: 20px;
  width: 20%;
  padding: 5px; /*border-bottom: 1px solid #e2e2e2; */
}
.shopAll_mn.shopProd_mn .shopProd_list .box .list ul li:nth-child(2n) {
  border-left: 1px solid #e2e2e2;
}
.shopAll_mn.shopProd_mn .shopProd_list .box .list ul li span {
  padding: 3px 8px;
  font-weight: 600;
}
.shopAll_mn.shopProd_mn .shopProd_list .box .list ul li .winQty {
  font-size: 15px;
  letter-spacing: 0.5px;
  font-weight: 300;
  color: #1f1f1f;
  font-family: var(--headingfont);
}
.shopAll_mn.shopProd_mn .shopProd_list .box .list ul li .winPrc {
  font-size: 16px;
  font-family: var(--robtslbfont);
  text-align: center;
}
.shopAll_mn.shopProd_mn .shopProd_list .box .list ul li .winPrc small {
  display: block;
  font-size: 12px;
  font-weight: 300;
  text-decoration: line-through;
  text-decoration-color: #ff5b2e;
}

/* .category_mn {
  padding: 50px 0;
} */
.category_list ul li {
  width: calc(100% / 4);
  padding: 15px;
}
.category_list {
  max-width: 12s00px;
  margin: auto;
}
.category_list ul li .catBtn {
  text-align: center;
}
.category_list ul li .catBtn a {
  display: block;
  line-height: 40px;
  font-size: 18px;
  font-family: sans-serif;
  text-decoration: none;
  color: #fff;
  border: 2px solid var(--orangecolor);
  letter-spacing: 2px;
  text-align: center;
  position: relative;
  transition: all 0.35s;
}
.category_list ul li .catBtn a:after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #ff4834;
  transition: all 0.35s;
  z-index: -1;
}
.category_list ul li .catBtn a:hover {
  color: var(--orangecolor);
}
.category_list ul li .catBtn a:hover:after {
  height: 0;
}

@media (max-width: 575px) {
  .main-contact-section h4.main-title {
    font-size: 26px;
    line-height: 100%;
  }
  .main-contact-section .main-subtitle {
    font-size: 14px;
    line-height: 140%;
    letter-spacing: 0;
    width: 100%;
  }
}

/* Contact Us Page E */

@media (min-width: 768px) {
  .shopAll_mn.shopProd_mn .shopProd_list {
    margin: 0 -2%;
  }
  .shopAll_mn.shopProd_mn .shopProd_list .shopProd_item {
    width: calc(100% / 2 - 4%);
    margin: 0 2%;
    margin-bottom: 30px;
  }
  .shopAll_mn.shopProd_mn .shopProd_list {
    margin: 0;
  }
}

@media (min-width: 992px) {
  .product_box .product_box_desc .title {
    font-size: 38px;
  }
  .header_container .inner {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  .shopAll_mn.shopProd_mn .shopProd_list {
    margin: 0 -2%;
  }
  .shopAll_mn.shopProd_mn .shopProd_list .shopProd_item {
    width: calc(100% / 3 - 4%);
    margin: 0 2%;
    margin-bottom: 30px;
  }
}

@media (min-width: 1200px) {
  .product_box .product_box_desc .title {
    font-size: 40px;
  }
  .product_box .product_box_desc .content {
    font-size: 30px;
  }
}
@media (max-width: 1599px) {
  .shopProd_mn .shopProd_list .shopProd_box .shopProd_detail .list ul li {
    width: 50%;
  }
}
@media (max-width: 1399px) {
  .header_container {
    padding: 8px 0;
  }
  .header_container .inner .main_nav .menu_wrap ul li {
    margin: 0 10px;
  }
  .header_container .inner .main_nav .menu_wrap ul li a {
    font-size: 16px;
  }
  .bannerSec .banner_sl .slider .slider_wrap .inner .left .thumbnail_wrap {
    height: 380px;
    padding-right: 75px;
  }
  .bannerSec
    .banner_sl
    .slider
    .slider_wrap
    .inner
    .right
    .detail_wrap
    .slider-title
    .title {
    font-size: 70px;
    line-height: 70px;
  }
  .bannerSec
    .banner_sl
    .slider
    .slider_wrap
    .inner
    .right
    .detail_wrap
    .content {
    font-size: 20px;
    line-height: 26px;
  }

  .fullImgBanner.bannerSec
    .banner_sl
    .slider
    .slider_wrap
    .inner
    .thumbnail_wrap {
    height: 450px;
  }

  .shopProd_mn {
    padding: 50px 0 20px;
  }
  .shopAll_mn.shopProd_mn .shopProd_list .shopProd_item {
    width: calc(100% / 2 - 30px);
    margin: 0 15px;
    margin-bottom: 30px;
  }
  .shopProd_mn .shopProd_list .shopProd_box .shopProd_img {
    width: 130px;
    margin: 0 auto;
  }
  /*.shopProd_mn .shopProd_list .shopProd_box .shopProd_detail { width: calc(100% - 130px); }*/
}
@media (max-width: 1199px) {
  .product_box .product_box_desc .content {
    font-size: 28px;
  }
  .product_mn .product_list .product_item {
    height: 240px;
  }

  .bannerSec .banner_sl .slider .slider_wrap .inner {
    padding: 30px 0;
  }
  .header_container .inner .main_nav .menu_wrap ul li a {
    font-size: 15px;
  }
  .header_container .inner .logo a img {
    /* anil */
    height: 80px;
    width: 110px;
  }
  .fullImgBanner.bannerSec
    .banner_sl
    .slider
    .slider_wrap
    .inner
    .thumbnail_wrap {
    height: auto;
  }
  .shopAll_mn.shopProd_mn .shopProd_list .box .detail .title h3 {
    font-size: 18px;
  }
  .bannerSec .banner_sl.slick-slider .slick-arrow.slick-next {
    right: 0;
    left: auto;
  }
  .bannerSec .banner_sl.slick-slider .slick-arrow.slick-prev {
    left: 0;
  }
  .category_list ul li {
    width: calc(100% / 3);
  }
}

@media (max-width: 991px) {
  .product_box .product_box_desc .content {
    font-size: 24px;
  }
  .bannerSec .banner_sl .slider .slider_wrap .inner .left .thumbnail_wrap {
    height: 380px;
    padding-right: 50px;
  }
  .bannerSec
    .banner_sl
    .slider
    .slider_wrap
    .inner
    .right
    .detail_wrap
    .slider-title
    .title {
    font-size: 52px;
  }
  .bannerSec
    .banner_sl
    .slider
    .slider_wrap
    .inner
    .right
    .detail_wrap
    .content {
    font-size: 20px;
  }
  .product_mn .product_list .product_item {
    width: 100%;
    margin-right: 0;
    margin-bottom: 15px;
  }
  .product_mn .product_list .product_item .product_box {
    padding: 50px 30px;
  }
  .header_container .inner {
    justify-content: space-between;
  }
  .header_container .inner .main_nav {
    position: absolute;
    top: 90px;
    left: 0;
    right: 0;
    width: 100%;
    background-color: #fbfbfb;
    z-index: 9;
    border-top: 5px solid #ff3f2a;
    box-shadow: 1px 2px 5px 3px #d7d7d7;
  }
  .header_container .inner .header-search.mb_search {
    display: block;
  }
  .header_container .inner .header-search.dt_search {
    display: none;
  }
  s .header_container .inner .main_nav .menu_wrap ul {
    flex-flow: column wrap;
    align-items: flex-start;
  }
  .header_container .inner .main_nav .menu_wrap ul li {
    margin: 0;
    padding: 10px 20px;
    width: 100%;
  }
  .header_container .inner .main_nav .action-btn {
    margin: 20px;
  }
  /* .header_container .inner .logo {
    margin-right: auto;
  } */
  .main-contact-section .form-wrapper {
    padding: 30px 0;
  }
  .shopProd_mn .shopProd_list {
    justify-content: center;
  }
  .shopAll_mn.shopProd_mn .shopProd_list {
    flex-flow: column wrap;
  }
  .shopAll_mn.shopProd_mn .shopProd_list .shopProd_item {
    width: 100%;
    margin: 0;
    margin-bottom: 30px;
  }

  .category_list ul li {
    width: calc(100% / 2);
    padding: 10px;
  }
}
@media (max-width: 767px) {
  .whatsappbtn {
    width: 40px;
    height: 40px;
    right: 10px;
    bottom: 90px;
  }
  .phonebtn {
    width: 30px;
    height: 30px;
    right: 15px;
  }
  .shopProd_mn {
    padding: 30px 0;
  }
  .bannerSec .banner_sl {
    height: auto;
  }
  .bannerSec
    .banner_sl
    .slider
    .slider_wrap
    .inner
    .right
    .detail_wrap
    .content {
    font-size: 18px;
  }
  .bannerSec .banner_sl .slider .slider_wrap .inner {
    display: flex;
    flex-flow: column wrap;
    width: 100%;
  }
  .bannerSec .banner_sl .slider .slider_wrap .inner .left {
    width: 100%;
  }
  .bannerSec .banner_sl .slider .slider_wrap .inner .right {
    width: 100%;
  }

  .shopProd_mn .shopProd_list {
    margin: 0;
  }
  .shopProd_mn .shopProd_list .shopProd_item {
    width: 100%;
    margin: 0;
    margin-bottom: 30px;
  }
  .bannerSec .banner_sl .slider .slider_wrap .inner .left .thumbnail_wrap {
    height: 380px;
    padding-right: 0;
    margin-bottom: 35px;
  }
  .shopProd_mn .shopProd_title {
    margin-bottom: 15px;
  }
  .shopAll_mn.shopProd_mn .shopProd_list .box .img_wrap {
    width: 150px;
  }
  .shopAll_mn.shopProd_mn .shopProd_list .box .detail {
    width: calc(100% - 150px);
    padding: 15px;
  }
  .shopAll_mn.shopProd_mn .shopProd_list .box .detail .title h3 {
    font-size: 20px;
    line-height: 28px;
  }
  .shopAll_mn.shopProd_mn .shopProd_list .box .detail .list ul li span {
    padding: 2px;
  }
  .header_container .inner .header-search .form-dropdown {
    max-width: 767px;
    padding: 15px 15px;
    top: -4px;
    width: 100%;
  }
}

/* .cover {
  object-fit: cover;
  width: 175px;
  height: 54px;
} */

@media (max-width: auto) {
  .header_container .inner .logo a img {
    height: auto;
  }
  .bannerSec
    .banner_sl
    .slider
    .slider_wrap
    .inner
    .right
    .detail_wrap
    .slider-title
    .title {
    font-size: 42px;
  }
  .product_box .product_box_desc .title {
    font-size: 28px;
  }
  .product_box .product_box_desc .content {
    font-size: 18px;
  }
  .product_mn .product_list {
    flex-direction: column;
  }
  .product_mn .product_item {
    width: 100%;
    height: 250px;
    margin-right: 0;
  }
  .product_mn .product_list .product_item {
    height: 200px;
  }
  .product_mn .product_item .product_box {
    padding: 30px;
  }
  .product_mn .product_item .product_list .product_item {
    width: 100%;
    margin-right: 0;
  }
  .product_mn .product_list .product_item .product_box {
    padding: 30px 20px;
  }

  .bannerSec .banner_sl .slider .slider_wrap .inner .left .thumbnail_wrap {
    height: 380px;
    padding-right: 0;
  }
  .bannerSec
    .banner_sl
    .slider
    .slider_wrap
    .inner
    .right
    .detail_wrap
    .content {
    font-size: 16px;
  }
  .main-contact-section .map_wrap .main-map {
    height: 350px;
  }
  .main-contact-section .contact-form {
    margin-bottom: 0;
  }
  .main-contact-section {
    padding: 0 0 0;
  }
  .shopAll_mn.shopProd_mn .shopProd_list .box .img_wrap {
    width: 100px;
  }
  .shopAll_mn.shopProd_mn .shopProd_list .box .detail {
    width: calc(100% - 100px);
    padding: 10px 5px;
  }
  .shopAll_mn.shopProd_mn .shopProd_list .box .detail .title h3 {
    font-size: 18px;
    line-height: 22px;
    margin: 0 0 5px 0;
    padding: 0 0 5px 0;
  }
  .shopAll_mn.shopProd_mn .shopProd_list .box .list ul li {
    width: 33.33%;
  }
  .shopAll_mn.shopProd_mn .shopProd_list .box .list ul li {
    justify-content: flex-start;
    font-size: 14px;
  }
  .shopAll_mn.shopProd_mn .shopProd_list .box .list ul li:nth-child(n) {
    border-left: 1px solid #e7e7e7;
    border-top: 1px solid #e7e7e7;
  }
  .shopAll_mn.shopProd_mn .shopProd_list .box .list ul li:nth-child(3n) {
    border-left: 1px solid #e2e2e2;
  }
  .shopAll_mn.shopProd_mn .shopProd_list .box .list ul li:nth-child(-n + 3) {
    border-top: 0px solid #f0f0f0;
  }
  .shopAll_mn.shopProd_mn .shopProd_list .box .list ul li:nth-child(3n + 3) {
    border-right: 0;
  }
  .shopAll_mn.shopProd_mn .shopProd_list .box .list ul li:nth-child(3n + 1) {
    border-left: 0px;
  }
  .shopAll_mn.shopProd_mn .shopProd_list .box .category span {
    letter-spacing: 0;
  }
  .shopAll_mn.shopProd_mn .shopProd_list .box .list ul li .winQty {
    font-size: 13px;
    letter-spacing: 0.5px;
  }
  .shopAll_mn.shopProd_mn .shopProd_list .box .list ul li .winPrc {
    font-size: 14px;
  }
  .shopAll_mn.shopProd_mn .shopProd_list .box .list ul li span {
    padding: 0;
  }
  .shopAll_mn.shopProd_mn .shopProd_list .box .bottom_part {
    padding: 5px;
  }
  /*    .shopProd_mn .shopProd_list .shopProd_box .shopProd_detail .list ul { flex-flow: column wrap; }
    .shopProd_mn .shopProd_list .shopProd_box .shopProd_detail .list ul li { width:100%; border-right:0; border-bottom:1px solid #949494 }    
    .shopProd_mn .shopProd_list .shopProd_box .shopProd_detail .list ul li:last-child {border-bottom: 0} */
  .shopProd_mn
    .shopProd_list
    .shopProd_box
    .shopProd_detail
    .list
    ul
    li:nth-child(3) {
    display: none;
  }
}
@media (max-width: 575px) {
  .header_container .inner .header-search .form-dropdown {
    max-width: 575px;
    padding: 15px;
    top: -15px;
    width: 100%;
  }
}

.title-part {
  display: flex !important;
  align-items: center !important;
  height: 12vh;
}
